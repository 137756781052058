.Home {
  height: 100%;
  width: 100%;
  
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(4, 1fr);
}

.video {
  display: flex;
  min-height: 100%;
  min-width: 100%;
  cursor: pointer;
}

.video video {
  min-height: 100%;
  min-width: 100%;
}

.video-placeholder {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: xx-large;
}

.video-placeholder > p {
  margin: 0;
}
