#recorder {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
  height: 100%;
}

#recorder > .top {
  height: 5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

#recorder > #preview {
  flex-shrink: 1;
  margin: 1rem 0;
  max-width: 100%;
  transform: scaleX(-1);
}
