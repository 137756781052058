#sign-in {
  font-size: larger;
  padding: 1rem 0.5rem;
}

#sign-in-form > div {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;
}

#sign-in-form input[type="submit"] {
  font-size: larger;
}