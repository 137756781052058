.App {
  text-align: center;
  height: 100%;
  width: 100%;
  margin: 0 auto;
  background-color: white;
}

@media (min-aspect-ratio: 1/1) {
  .App {
    width: 56.25vh;
  }
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none;   /* Safari */
   -khtml-user-select: none;   /* Konqueror HTML */
     -moz-user-select: none;   /* Old versions of Firefox */
      -ms-user-select: none;   /* Internet Explorer/Edge */
          user-select: none;   /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
