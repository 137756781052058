.syncer {
  display: block;
  width: 100%;
}

.syncer > video {
  flex-shrink: 1;
  max-width: 100%;
  transform: scaleX(-1);
}

.syncer input {
  width: 90%;
}

.syncer h2 {
  margin: 0.2rem 0;
}

.syncer .tooltip {
  margin: 0;
  margin-bottom: 0.2rem;
  font-size: small;
}

.syncer > div {
  margin-bottom: 1rem;
}
