.header {
  height: 7.5%;
  font-size: xx-large;
  font-weight: 500;
  
  background-color: #547AA5;

  display: flex;
  justify-content: center;
  align-items: center;
}