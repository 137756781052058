.landing {
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;

  color: white;
  background: linear-gradient(#4D5E68, #171719);

  padding: 0.5rem 1.5rem;
  box-sizing: border-box;

  cursor: default;
}

.top {
  display: flex;
  width: 100%;
  align-items: center;
}

.caret {
  width: 25%;
  text-align: left;
  font-size: x-large;
}

.top-info {
  width: 50%;
  text-align: center;
}

.playing-from {
  font-size: small;
  color: #C9D4DA;
}

.vertical-ellipsis {
  width: 25%;
  text-align: right;
  font-size: xx-large;
}

.middle {
  background-image: url("./album.jpg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;

  flex-grow: 1;

  margin: 2rem 0;
}

.bottom .row1 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: left;
  margin-bottom: 0.5rem;
}

.row1 .title {
  font-size: large;
}

.row1 .artist {
  color: #d9dbdf;
}

.heart {
  font-size: x-large;
}

.bottom .row3 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: xx-large;
}

.row3 .play {
  font-size: 5rem;
}

.row4 {
  display: flex;
  justify-content: space-between;
  font-size: x-large;
}

.select {
  cursor: pointer;
}
